/* global fetch */
import { getToken } from './useStorage';

const globalErrorHandling = async (error, data) => {
    let token;

    try {
        token = getToken();
    } catch (error) {
        console.error(error);

        return;
    }

    try {
        const response = await fetch('/event', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({ data, error, token })
        });

        if (response.statusCode === 200) {
            const json = await response.json();

            if (json.refresh) {
                window.location.reload(true);
            }
        }
    } catch (err) {
        console.error(err);
    }
};

export default globalErrorHandling;
